<template>
  <BaseList
    :list-query="counties"
    route="county"
    locale-section="pages.counties"
  >
    <template v-slot:extra="{ element: { letter, number, country } }">
      {{ letter }} / {{ number }} ( {{ country.country }} )
    </template>
  </BaseList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CountyList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      counties: gql`
        query counties {
          list: counties {
            id
            name: county
            county
            letter
            number
            country {
              country
            }
          }
        }
      `
    };
  }
};
</script>
